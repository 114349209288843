import React from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import classes from './ProfileTabSide.module.scss';
interface ProfileTabSideProps {
  className?: string;
  children: React.ReactNode;
  onlyDesktop?: boolean;
  side?: 'left' | 'right';
}

const ProfileTabSide: React.FC<ProfileTabSideProps> = ({
  className,
  children,
  onlyDesktop,
  side = 'right',
}) => {
  return (
    <Flex
      className={cnj(
        classes.profileTabSideRoot,
        onlyDesktop && classes.onlyDesktop,
        side === 'left' ? classes.right : classes.left,
        className
      )}
    >
      {children}
    </Flex>
  );
};

export default ProfileTabSide;
