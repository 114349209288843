import React from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import ProfileTabMain from './ProfileTabMain';
import ProfileTabSide from './ProfileTabSide';
import classes from './ProfileTabBiColumn.module.scss';

export interface ProfileTabBiColumnProps {
  className?: string;
  showSideOnDesktop?: boolean;
  mainComponent?: React.ReactNode;
  sideComponent?: React.ReactNode;
}

const ProfileTabBiColumn: React.FC<ProfileTabBiColumnProps> = ({
  className,
  showSideOnDesktop,
  mainComponent,
  sideComponent,
}) => {
  return (
    <Flex className={cnj(classes.profileTabBiColumnRoot, className)}>
      <ProfileTabMain
        className={cnj(showSideOnDesktop && classes.omitMarginTop)}
      >
        {mainComponent}
      </ProfileTabMain>
      <ProfileTabSide onlyDesktop={showSideOnDesktop}>
        {sideComponent}
      </ProfileTabSide>
    </Flex>
  );
};

export default ProfileTabBiColumn;
