import React from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import ProfileTabBiColumn from './ProfileTabBiColumn';
import ProfileTabTop from './ProfileTabTop';
import ProfileTabFullwidth from './ProfileTabFullwidth';
import classes from './ProfileTabLayout.module.scss';

interface ProfileTabLayoutProps {
  className?: string;
  showSideOnDesktop?: boolean;
  topComponent?: React.ReactNode;
  mainComponent?: React.ReactNode;
  sideComponent?: React.ReactNode;
  children?: React.ReactNode;
}

const ProfileTabLayout: React.FC<ProfileTabLayoutProps> = ({
  className,
  showSideOnDesktop,
  topComponent,
  mainComponent,
  sideComponent,
  children,
}) => {
  const doesRenderBiColumn = mainComponent && sideComponent;

  if ((mainComponent && !sideComponent) || (!mainComponent && sideComponent)) {
    throw new Error(
      'You cannot use one of mainComponent or sideComponent, use both or do not use both'
    );
  }

  return (
    <Flex className={cnj(classes.profileTabLayoutRoot, className)}>
      {topComponent && <ProfileTabTop>{topComponent}</ProfileTabTop>}
      {doesRenderBiColumn && (
        <ProfileTabBiColumn
          showSideOnDesktop={showSideOnDesktop}
          mainComponent={mainComponent}
          sideComponent={sideComponent}
        />
      )}
      {children && <ProfileTabFullwidth>{children}</ProfileTabFullwidth>}
    </Flex>
  );
};

export default ProfileTabLayout;
