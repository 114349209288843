import React from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import classes from './ProfileTabTop.module.scss';

interface ProfileTabTopProps {
  className?: string;
  children: React.ReactNode;
}

const ProfileTabTop: React.FC<ProfileTabTopProps> = ({
  className,
  children,
}) => {
  return (
    <Flex className={cnj(classes.profileTabTopRoot, className)}>
      {children}
    </Flex>
  );
};

export default ProfileTabTop;
