'use client';

import ProfileTabLayout from '@lobox/uikit/Layout/Profile/ProfileTabLayout';
import Skeleton from '@lobox/uikit/Skeleton/Skeleton';
import classes from './loading.module.scss';

export default function loading() {
  return (
    <ProfileTabLayout
      topComponent={<Skeleton className={classes.skeleton__top} />}
      mainComponent={<Skeleton className={classes.skeleton__main} />}
      sideComponent={<Skeleton className={classes.skeleton__side} />}
    />
  );
}
