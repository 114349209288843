import React from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import classes from './ProfileTabFullwidth.module.scss';

interface ProfileTabFullwidthProps {
  className?: string;
  children: React.ReactNode;
}

const ProfileTabFullwidth: React.FC<ProfileTabFullwidthProps> = ({
  className,
  children,
}) => {
  return (
    <Flex className={cnj(classes.profileTabFullwidthRoot, className)}>
      {children}
    </Flex>
  );
};

export default ProfileTabFullwidth;
