import React from 'react';
import cnj from '../../utils/cnj';
import Flex from '../../Flex';
import classes from './ProfileTabMain.module.scss';

interface ProfileTabMainProps {
  className?: string;
  children: React.ReactNode;
}

const ProfileTabMain: React.FC<ProfileTabMainProps> = ({
  className,
  children,
}) => {
  return (
    <Flex className={cnj(classes.profileTabMainRoot, className)}>
      {children}
    </Flex>
  );
};

export default ProfileTabMain;
